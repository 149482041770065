import { Box, Grid } from '@material-ui/core'
import { Spacer } from 'common/components/molecules/Spacer'
import { Text } from 'common/components/molecules/Text'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'
import { JumbotronCallToAction } from '../Jumbotron/JumbotronCallToAction'
import { FeatureListProps, featureListStyles } from './FeatureList'
import { FeatureListImage } from './FeatureListImage'
import { Features } from './Features'

export const FeatureListRight: React.FC<FeatureListProps> = ({
    features,
    image,
    scale,
    title,
    callToAction,
    cta,
    text,
    subtitle,
    featureTitle,
    featureSubtitle,
    secondaryTitle
}) => {
    const desktop = useDesktop()
    return (
        <Box data-testid='FeatureListRight' component='section'>
              {secondaryTitle && (
                <Text
                box={{ mb: 6, color: '#4DB7AB' }}
                label={secondaryTitle}
                variant='overline'
                component='h1'
            />
            )}
            <Box mx={[0, 0, 24]}>
                <Text
                    variant={desktop ? 'h4' : 'h2'}
                    box={{ mb: 6, textAlign: desktop ? 'center' : undefined }}
                    label={featureTitle || ''}
                />
                <Text
                    variant='subtitle1'
                    box={{ textAlign: desktop ? 'center' : undefined }}
                    color='textSecondary'
                    html
                    label={subtitle || ''}
                />
                {subtitle && <Spacer variant={'md'} />}
            </Box>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ display: 'flex', order: desktop ? 1 : 3 }}
                >
                    <Box {...featureListStyles.column}>
                        <Features
                            features={features}
                            title={title}
                            subtitle={featureSubtitle}
                            spacerVariant={'none'}
                        />
                        <Text label={text || ''} html={true} />

                        {callToAction && cta === 'true' ? (
                            <JumbotronCallToAction {...callToAction} />
                        ) : null}
                    </Box>
                </Grid>
                <Grid item md={1} style={{ order: 2 }} />
                <Grid item xs={12} md={5} style={{ order: desktop ? 3 : 1, display: 'flex' }}>
                    <Box {...featureListStyles.column}>
                        {title && !desktop && (
                            <>
                                <Text
                                    label={title}
                                    variant={desktop ? 'h4' : 'h2'}
                                />
                                <Text
                                    label={featureSubtitle ? featureSubtitle : ''}
                                    variant={'subtitle2'}
                                />
                            </>
                        )}
                        {!desktop && <Spacer variant='md' />}
                        <FeatureListImage image={image} variant='right' />
                        {!desktop && <Spacer variant='md' />}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
