import { Box } from '@material-ui/core'
import { Spacer } from 'common/components/molecules/Spacer'
import { Text } from 'common/components/molecules/Text'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'
import { Feature, FeatureProps } from './Feature'

export interface FeaturesProps {
    features: FeatureProps[]
    title?: string
    subtitle?: string
    spacerVariant?: "md" | "xs" | "sm" | "lg" | 'none' | undefined
}

export const Features: React.FC<FeaturesProps> = ({
    features = [],
    title,
    subtitle,
    spacerVariant = 'md'
}) => {
    const desktop = useDesktop()
    return (
        <Box>
            {spacerVariant !== 'none' &&
                <Spacer variant={spacerVariant} />
            }
            {title && desktop && (
                <Text
                    box={{ mb: subtitle ? 2 : 8 }}
                    label={title}
                    variant='h4'
                />
            )}
            {subtitle && desktop && (
                <Text
                    box={{ mb: 8 }}
                    label={subtitle}
                    style={{ color: '#766F90', fontSize: '20px' }}
                />
            )}
            {features.map((feature, idx) => (
                <Feature
                    key={feature.title || feature.text || idx}
                    {...feature}
                />
            ))}
        </Box>
    )
}
