
import { ContentItem, ItemResponses } from '@kentico/kontent-delivery'
import blackList from './site_data_blacklist';

export const filterBlacklistedSiteData = (
    rawSiteData: ItemResponses.ViewContentItemResponse<ContentItem>
): ItemResponses.ViewContentItemResponse<ContentItem> => {

    const blacklistTerms = blackList;

    const filteredLinkedItems: ItemResponses.ViewContentItemResponse<ContentItem>['linkedItems'] =
        {}

    for (const key in rawSiteData.linkedItems) {
        const item = rawSiteData.linkedItems[key]

        const isBlacklisted = blacklistTerms.some((term: string) => {
            return item?.system.name.startsWith(term)
        })

        if (isBlacklisted) {
            continue
        }

        filteredLinkedItems[key] = item
    }

    rawSiteData.linkedItems = filteredLinkedItems

    return rawSiteData
}
