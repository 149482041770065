import { IContentItemsContainer } from '@kentico/kontent-delivery'
import { kentico } from '../../../common/services/kentico'
import { getValuesFromCmsData } from '../../../common/utils/getValuesFromCmsData'
import { useQuery } from 'react-query'
import { filterBlacklistedSiteData } from './blacklist/filterBlacklistedSiteData'

export const useSiteData = () => {
    
    // Get data from Kentico via react-query
    const cmsQuery = useQuery('data', () => autoPaginationQuery('home'), {
    // const cmsQuery = useQuery('data', () => kentico.getItem('home'), { //Previous solution, which generated the 2000 item limit error
        refetchOnWindowFocus: false,
    })
    if (!cmsQuery.data) return null
    
    const filteredSiteData = filterBlacklistedSiteData(cmsQuery.data);
    const siteData = getValuesFromCmsData(filteredSiteData);
    return siteData
}

export const autoPaginationQuery = async (mainElement: string) => {
    let linkedItems: IContentItemsContainer = {};
    let firstEl = await kentico.getItem(mainElement, 0);
    linkedItems = {...linkedItems, ...firstEl.linkedItems};
    
    let itemsAndLinkedItems = await findByItemsFeed();
    linkedItems = {...linkedItems, ...itemsAndLinkedItems};

    //Checks all items to see if there are any children that have not been imported. If there are, search for them and insert them into the list.
    const missingLinkedItems = await checkAndFindMissingComponents(linkedItems);
    linkedItems = {...linkedItems, ...missingLinkedItems};
    
    firstEl.linkedItems = linkedItems;
    return firstEl;
} 

const findByItemsFeed = async () => {
    let itemsAndLinkedItems: IContentItemsContainer = {};

    const response = await kentico.getItemsFeed();
    
    response.items.forEach(e => {
        itemsAndLinkedItems[e.system.codename.toString()] = e;
    });

    itemsAndLinkedItems = {...itemsAndLinkedItems, ...response.linkedItems};

    return itemsAndLinkedItems;
}

const checkAndFindMissingComponents = async (linkedItems: Record<string, any>) => {
    let missingItems: IContentItemsContainer = {};
    
    for(const itemKey in linkedItems) {
        for(const childKey in linkedItems[itemKey]) {
            if(linkedItems[itemKey][childKey]) {
                if(linkedItems[itemKey][childKey].linkedItemCodenames && linkedItems[itemKey][childKey].linkedItemCodenames.length > 0) {
                    for (const linkedItemCodename of linkedItems[itemKey][childKey].linkedItemCodenames) {
                        if(!linkedItems[linkedItemCodename]) {
                            const itemWithChilds = await kentico.getItem(itemKey, 10);
                            missingItems = {...missingItems, ...itemWithChilds.linkedItems};
                        }
                    }
                }
            }
        }
    }

    return missingItems;
}