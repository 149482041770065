import { BreadcrumbsInterface } from 'common/components/molecules/Breadcrumbs'
import { FooterProps } from 'common/components/organisms/Footer'
import * as humps from 'humps'
import _ from 'lodash'
import { getBlogPagesData } from './getBlogPosts'
import { getFooterData } from './getFooterData'
import { getLogin } from './getLogin'
import { getNavigation } from './getNavigation'
import { getSiteSearchData } from './getSiteSearchData'

export interface Page extends PagePath {
    template: string
    context: any
}

interface PagePath {
    path: string
}

interface PageBreadcrumbs {
    breadcrumbs: BreadcrumbsInterface
}

interface PageNonContextData {
    codename: string
    footer__variant: string
    lastModified: Date
    navigation__breadcrumb_title: string
    navigation_items?: PageNonContextData[]
    type: string
    url?: string
    blog_post_category?: string
    footer__regulatory_text?: { text: string }[]
    contact_form?: any[]
    talk_to_an_advisor_contact_form?: any[]
}

export interface PageData
    extends PageBreadcrumbs,
        PagePath,
        PageNonContextData {}

export const getPages = ({
    baseBreadcrumbs = [],
    basePath = '',
    blogPosts = [],
    navigationItems,
    siteData,
}: {
    baseBreadcrumbs?: BreadcrumbsInterface
    blogPosts?: any[]
    basePath?: string
    navigationItems: PageNonContextData[]
    siteData: any
}) => {
    const navigation = getNavigation(siteData)
    const login = getLogin(siteData)
    const pagesData = [...navigationItems, ...blogPosts].reduce(
        (result: Page[], { navigation_items, ...pageDataItem }) => {
            // Get page URL & breadcrumbs array
            const { breadcrumbs, path, productLine } = getElement(
                pageDataItem,
                baseBreadcrumbs,
                basePath
            )
            // Only generate pages for included content types
            if (!isExcludedByType(pageDataItem.type, pageDataItem.codename)) {
                // For content type === 'product_line' get the context from introduction page
                let pageDataModified: PageNonContextData = pageDataItem

                if (isProductLineType(pageDataItem.type)) {
                    pageDataModified =
                        _.find(navigation_items, (item) =>
                            isIntroductionPage(item.codename)
                        ) || ({} as PageNonContextData)
                }
                const getProductline = (type: string) => {
                    switch (type) {
                        case 'blog_post':
                            return (pageDataModified.blog_post_category || '')
                                .toLowerCase()
                                .replace(/ /gi, '-')
                        case 'blog_landing':
                            return 'blog_landing'
                        default:
                            return productLine
                    }
                }

                getProductline(pageDataModified.type)

                const page: Page = {
                    context: {
                        ...pageDataModified,
                        blogPosts: getBlogPagesData(blogPosts, path),
                        layout: {
                            breadcrumbs: { breadcrumbs },
                            footer: getFooterData(
                                siteData,
                                getProductline(pageDataModified.type),
                                pageDataModified.footer__variant as FooterProps['variant'],
                                pageDataModified.footer__regulatory_text?.[0]
                                    .text,
                                pageDataModified.contact_form?.[0] ||
                                    pageDataModified
                                        .talk_to_an_advisor_contact_form?.[0]
                            ),
                            login,
                            navigation,
                        },
                        siteData: path === '/site-map' ? siteData : undefined,
                    },
                    path: `${path}/`,
                    template: getTemplatePath(pageDataModified.type),
                }
                result.push(page)
            }

            // Recurrency
            if (navigation_items?.length) {
                result = result.concat(
                    getPages({
                        basePath: path,
                        baseBreadcrumbs: breadcrumbs,
                        navigationItems: navigation_items,
                        siteData,
                    })
                )
            }
            return result
        },
        []
    )

    const siteSearchData = getSiteSearchData(pagesData)

    return pagesData.map(({ context, ...rest }) => ({
        context: { ...context, layout: { ...context.layout, siteSearchData } },
        ...rest,
    }))
}

const getElement = (
    pageDataItem: PageNonContextData,
    baseBreadcrumbs: BreadcrumbsInterface,
    basePath: string = ''
): { path: string; breadcrumbs: BreadcrumbsInterface; productLine: string } => {
    if (isExcludedByType(pageDataItem.type, pageDataItem.codename))
        return {
            path: basePath,
            breadcrumbs: baseBreadcrumbs,
            productLine: basePath.split('/')[1],
        }

    const elementPath = (
        pageDataItem.type === 'blog_post'
            ? `/blog/${pageDataItem.url}`
            : `${
                  isLifeCareSubproduct(pageDataItem) ? '/lifecare' : ''
              }${modifyCodename(pageDataItem.codename)}`
    ).replace(/_/gi, '-')

    const path = `${basePath}${elementPath}`

    const elementBreadcrumb = getElementBreadcrumbs(
        pageDataItem,
        baseBreadcrumbs
    )
    const breadcrumbs = elementBreadcrumb.concat({
        name: pageDataItem.navigation__breadcrumb_title,
        url: path,
    })

    return {
        path,
        breadcrumbs,
        productLine: path.split('/')[1],
    }
}
const getElementBreadcrumbs = (
    pageDataItem: PageNonContextData,
    breadcrumbs: BreadcrumbsInterface
): BreadcrumbsInterface => {
    if (isLifeCareSubproduct(pageDataItem))
        return breadcrumbs.concat({
            name: 'Lifecare',
            url: '/life-insurance/lifecare',
        })
    if (isBlogPost(pageDataItem.type))
        return breadcrumbs.concat({
            name: 'Blog',
            url: '/blog',
        })
    return breadcrumbs
}

const isLifeCareSubproduct = ({
    codename,
    type,
}: PageNonContextData): boolean =>
    type === 'product_lifecare' && codename !== 'lifecare'

const isIntroductionPage = (codename: string): boolean =>
    codename.includes('introduction') || (codename.includes('get_advice') && codename !== 'get_advice_2') //TO DO - Deprecated code - check if this condition is necessary

const isProductLineType = (type: string): boolean => type === 'product_line'

const isExcludedByType = (type: string, codename: string): boolean => {
    if(!type) return true
    
    return (type.includes('introduction') &&
        // #RR-1408
        codename !== 'company_pension_customers') ||
    type.includes('product_group') ||
    type === 'app_line' ||
    type === 'link' ||
    codename === 'your_goals'
}

const isBlogPost = (type: string) => type === 'blog_post'

const modifyCodename = (codename: string): string => {
    if (codename.includes('get_advice') && codename !== 'get_advice_2') return '/get_advice' //TO DO - Deprecated code - check if this condition is necessary
    if (isIntroductionPage(codename)) return ''
    if (codename.includes('calculator__')) return '/quote'
    return `/${codename}`
}

export const getTemplatePath = (templateName: string) => {
    return `${humps.pascalize(templateName || '')}Page`
}
